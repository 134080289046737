import { createDataTable } from "../../../utils/TableUtils";
import { Column, Data } from "../../../models/TableModels";
import { MasterTaskFilter } from "./CHMMasterSnippets";
import { useEffect, useState } from "react";
import { DEFAULT_OPTION } from "../../../utils/Constant";
import { useDispatch } from "react-redux";
import { CHMSurveyorAssigne, getCHMSurveyorData } from "../../../api/chmActivityAPI";

const CHMMasters = () => {
  const dispatch: any = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState<any>({
    state: DEFAULT_OPTION,
    district: DEFAULT_OPTION,
    mandal: DEFAULT_OPTION,
    surveyorName: DEFAULT_OPTION,
    file: {},
  });

  useEffect(() => {
    if (
      selectedFilter.mandal.value != null &&
      selectedFilter.surveyorName != null
    ) {
      dispatch(
        CHMSurveyorAssigne(
          selectedFilter.state.value,
          selectedFilter.district.value,
          selectedFilter.mandal.value,
          selectedFilter.surveyorName
        )
      );
    }
  }, [dispatch, selectedFilter]);

  return (
    <div style={{ height: "80vh" }}>
      <MasterTaskFilter onApplyFilters={setSelectedFilter} />
    </div>
  );
};

export default CHMMasters;
