import { useEffect, useState } from "react";
import {
  CHMFilterType,
  LocationDataType,
} from "../../../models/CHMActivitiesModels";
import { Col, Row } from "reactstrap";
import { DEFAULT_OPTION } from "../../../utils/Constant";
import SelectBox from "../../../components/SelectBox";

import KDatePicker from "../../../components/KDatePicker";
import moment from "moment";
import {
  getDistrictsDataAPICall,
  getStatesDataAPICall,
  getMandalDataAPICall,
  getAllVillagesDataAPICall,
  getAllSeasonsDataAPICall,
  getCropBySearchAPICall,
  getCHMSurveyorData,
} from "../../../api/chmActivityAPI";
import { useDispatch, useSelector } from "react-redux";
import { FormUtils } from "../../../utils/FormUtils";

interface CHMFilterProps {
  filterRawData?: LocationDataType[];
  onApplyFilters: (CHMFilter: CHMFilterType) => void;
}

export const ViewTaskFilter = ({
  onApplyFilters,
  filterRawData,
}: CHMFilterProps) => {
    const LOCAL_STORAGE_KEY = "chmFilterState";

  const [chmFilter, setCHMFilter] = useState<CHMFilterType>(() => {
    // Load from localStorage or use default values
    const savedFilter = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedFilter
      ? JSON.parse(savedFilter)
      : {
          state: DEFAULT_OPTION,
          district: DEFAULT_OPTION,
          mandal: DEFAULT_OPTION,
          village: DEFAULT_OPTION,
          season: DEFAULT_OPTION,
          cropName: DEFAULT_OPTION,
          search: DEFAULT_OPTION,
          fromDate: undefined,
          toDate: undefined,
          year: DEFAULT_OPTION,
        };
  });

  const dispatch: any = useDispatch();
  useEffect(() => {
    // Clear localStorage when the page is refreshed
    window.addEventListener("beforeunload", () => localStorage.removeItem(LOCAL_STORAGE_KEY));

    dispatch(getCHMSurveyorData(""));
    dispatch(getStatesDataAPICall());
    dispatch(getAllSeasonsDataAPICall());
    dispatch(getCropBySearchAPICall(""));

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener("beforeunload", () =>
        localStorage.removeItem(LOCAL_STORAGE_KEY)
      );
    };
  }, [dispatch]);

  //   // Save filter state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(chmFilter));
  }, [chmFilter]);


  const onResetFilter = () => {
    const resetFilter = {
      state: DEFAULT_OPTION,
      district: DEFAULT_OPTION,
      mandal: DEFAULT_OPTION,
      village: DEFAULT_OPTION,
      season: DEFAULT_OPTION,
      cropName: DEFAULT_OPTION,
      search: DEFAULT_OPTION,
      fromDate: undefined,
      toDate: undefined,
      year: DEFAULT_OPTION,
    };
    setCHMFilter(resetFilter);
    onApplyFilters(resetFilter);
  };

  const {
    storeCHMGetStatesData,
    storeCHMGetDistrictsData,
    storeCHMGetMandalData,
    storeCHMGetVillagesData,
    storeCHMGetSeasonsData,
    storeCHMGetCropsData,
    storeCHMSurveyorData,
  } = useSelector((state: any) => state.chmActivity);
  const yearsList: any = [
    {
      label: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`,
      value: `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`,
    },
    {
      label: `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`,
      value: `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`,
    },
    {
      label: `${new Date().getFullYear() - 2}-${new Date().getFullYear() - 1}`,
      value: `${new Date().getFullYear() - 2}-${new Date().getFullYear() - 1}`,
    },
  ];

  const surveyorsData = FormUtils.convertSurveyorDataList(
    storeCHMSurveyorData.data ?? []
  );

  const statesData = FormUtils.convertDataList(
    storeCHMGetStatesData.data ?? []
  );

  const districtsData = FormUtils.convertDataList(
    storeCHMGetDistrictsData.data ?? []
  );

  const mandalData = FormUtils.convertDataList(
    storeCHMGetMandalData.data ?? []
  );
  const villagesData = FormUtils.convertDataList(
    storeCHMGetVillagesData.data ?? []
  );

  const seasonsData = FormUtils.convertDataList(
    storeCHMGetSeasonsData.data ?? []
  );

  const cropsData = FormUtils.convertDataList(storeCHMGetCropsData.data ?? []);

  const onchangeDropdown = (label: String, event: any) => {
    const updatedFilter = { ...chmFilter, [label.toLowerCase()]: event };
    setCHMFilter(updatedFilter);
    switch (label) {
      case "State":
        dispatch(getDistrictsDataAPICall(event.value));
        break;
      case "District":
        if (typeof chmFilter.state.value === "number") {
          dispatch(getMandalDataAPICall(chmFilter.state.value, event.value));
        }

        break;
      case "mandal":
        if (
          typeof chmFilter.state.value === "number" &&
          typeof chmFilter.district.value === "number"
        ) {
          dispatch(
            getAllVillagesDataAPICall(
              chmFilter.state.value,
              chmFilter.district.value,
              event.value
            )
          );
        }
        break;

      default:
        dispatch(getDistrictsDataAPICall(event.value));
        break;
    }
  };

  return (
    <div className="border rounded ps-3 pt-2 pe-3 pb-3 mb-3">
      <Row>
        <Col xs={12} md={4} lg={2}>
          <SelectBox
            label="State"
            options={statesData}
            className="css-select-bg"
            value={chmFilter.state}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  state: event,
                  district: DEFAULT_OPTION,
                  mandal: DEFAULT_OPTION,
                  village: DEFAULT_OPTION,
                });
                onchangeDropdown("State", event);
              } else {
                setCHMFilter({
                  ...chmFilter,
                  state: DEFAULT_OPTION,
                  district: DEFAULT_OPTION,
                  mandal: DEFAULT_OPTION,
                  village: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
        <Col xs={12} md={6} lg={3}>
          <SelectBox
            disabled={chmFilter.state.label === "Select"}
            label="District"
            value={chmFilter.district}
            options={districtsData}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  district: event,
                  mandal: DEFAULT_OPTION,
                  village: DEFAULT_OPTION,
                });

                onchangeDropdown("District", event);
              } else {
                setCHMFilter({
                  ...chmFilter,
                  district: DEFAULT_OPTION,
                  mandal: DEFAULT_OPTION,
                  village: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
        <Col xs={12} md={4} lg={2}>
          <SelectBox
            disabled={chmFilter.district.label === "Select"}
            label="Mandal"
            options={mandalData}
            className="css-select-bg"
            value={chmFilter.mandal}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  mandal: event,
                  village: DEFAULT_OPTION,
                });
                onchangeDropdown("mandal", event);
              } else {
                setCHMFilter({
                  ...chmFilter,
                  mandal: DEFAULT_OPTION,
                  village: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
        <Col xs={12} md={4} lg={2}>
          <SelectBox
            disabled={chmFilter.mandal.label === "Select"}
            label="Village"
            options={villagesData}
            className="css-select-bg"
            value={chmFilter.village}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  village: event,
                });
              } else {
                setCHMFilter({
                  ...chmFilter,
                  village: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4} lg={2}>
          <SelectBox
            label="Season"
            options={seasonsData}
            className="css-select-bg"
            value={chmFilter.season}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  season: event,
                });
              } else {
                setCHMFilter({
                  ...chmFilter,
                  season: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
        <Col xs={12} md={6} lg={3}>
          <SelectBox
            label="Year"
            value={chmFilter.year}
            options={yearsList}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  year: event,
                });
              } else {
                setCHMFilter({
                  ...chmFilter,
                  year: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
        <Col xs={12} md={4} lg={2}>
          <KDatePicker
            label="From Date"
            value={chmFilter.fromDate}
            minDate={moment().subtract(1, "years").toDate()}
            maxDate={new Date()}
            onChange={(date) => {
              setCHMFilter({
                ...chmFilter,
                fromDate: date,
                toDate: undefined,
              });
            }}
          />
        </Col>
        <Col xs={12} md={6} lg={3}>
          <KDatePicker
            disabled={chmFilter.fromDate === undefined}
            label="Date To"
            minDate={chmFilter.fromDate}
            maxDate={new Date()}
            value={chmFilter.toDate}
            onChange={(date) => {
              setCHMFilter({
                ...chmFilter,
                toDate: date,
              });
            }}
          />
        </Col>
        <Col xs={12} md={4} lg={2}>
          <SelectBox
            label="Crop Name"
            options={cropsData}
            className="css-select-bg"
            value={chmFilter.cropName}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  cropName: event,
                });

                // onchangeDropdown("Crop Name", event);
              } else {
                setCHMFilter({
                  ...chmFilter,
                  cropName: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={3}>
          <SelectBox
            label="Search"
            options={surveyorsData}
            className="css-select-bg"
            value={chmFilter.search}
            onChange={(event: any) => {
              if (event != null) {
                setCHMFilter({
                  ...chmFilter,
                  search: event,
                });
              } else {
                setCHMFilter({
                  ...chmFilter,
                  search: DEFAULT_OPTION,
                });
              }
            }}
          />
        </Col>

        <Col xs={6} md={3} className="d-flex align-items-center mb-2">
          <button
            style={{ marginLeft: 0 }}
            className="button"
            onClick={() => {
              onApplyFilters(chmFilter);
            }}
          >
            {" "}
            Apply Filters{" "}
          </button>
          <button
            className="button"
            onClick={() => {
              onResetFilter();
            }}
          >
            {" "}
            Reset{" "}
          </button>
        </Col>
      </Row>
    </div>
  );
};
